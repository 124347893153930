import type React from 'react';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  isRouteErrorResponse,
  useNavigation,
  useLocation,
} from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import clsx from 'clsx';
import { LoadingImage, LoadingText } from '~/components/shared/Loading';
import NotFound from '~/components/shared/NotFound';
import ServerError from '~/components/shared/ServerError';
import { PtnToast } from '~/components/ui/Others/PtnToast';
import { PtnToastProvider } from '~/components/ui/Others/PtnToastProvider';
import { isGtmPushMode } from '~/hooks/gtm/isGtmPushMode';
import { useGtmPushPageView } from '~/hooks/gtm/useGtmPushPageView';
import styles from '~/styles/tailwind.css?url';
import { PARTNER_GTM_URL } from '~/utils/urls';
import type { LinksFunction } from '@remix-run/node';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

const FullScreenLoader = ({ isLoading }) => {
  return (
    <div
      className={clsx(
        'fixed inset-0 flex flex-col items-center justify-center',
        !isLoading && 'hidden',
      )}
    >
      <LoadingImage />
      <LoadingText className="mt-2" />
    </div>
  );
};

export function Layout({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  useGtmPushPageView();
  const navigation = useNavigation();
  const location = useLocation();
  const isLoading =
    navigation.state === 'loading' &&
    location.pathname !== navigation.location?.pathname;
  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="font-15 font-gray-800">
        {isGtmPushMode(import.meta.env.MODE) && (
          <noscript>
            <iframe
              src={PARTNER_GTM_URL}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
              title="Google Tag Manager"
            ></iframe>
          </noscript>
        )}
        <PtnToastProvider>
          <PtnToast />
          <FullScreenLoader isLoading={isLoading} />
          <div
            className={clsx(
              'transition-opacity duration-700',
              isLoading ? 'opacity-40 pointer-events-none' : 'opacity-100',
            )}
          >
            {children}
          </div>
        </PtnToastProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary(): JSX.Element {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFound />;
  }
  return <ServerError />;
}

function App(): React.ReactElement {
  return <Outlet />;
}

export default withSentry(App);
