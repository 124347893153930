const ServerError: React.FC = () => {
  return (
    <div className="max-w-[1024px] mx-auto h-[686px] p-2">
      <header className="pt-2 px-4">
        <img
          src="/partner_dashboard_logo.png"
          alt="パートナー管理画面"
          className="h-4"
        />
      </header>
      <div className="flex flex-col items-start justify-start px-4">
        <h1 className="text-34 font-bold mt-3">
          一時的にサービスをご利用いただけません
        </h1>
        <p className="text-18 mt-4">
          大変申し訳ありませんが、しばらく経ってから再度アクセスしてください。
        </p>
      </div>
    </div>
  );
};

export default ServerError;
