import { useEffect } from 'react';
import { useLocation } from '@remix-run/react';
import { isGtmPushMode } from '~/hooks/gtm/isGtmPushMode';
import { usePartnerStore } from '~/store/partner';
import { PARTNER_GTM_URL } from '~/utils/urls';
import type { PageViewEvent } from '~/types/gtm';

export const useGtmPushPageView: () => void = () => {
  const { partner } = usePartnerStore();
  const location = useLocation();

  useEffect(() => {
    if (!isGtmPushMode(import.meta.env.MODE)) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'gtm.js',
      partnerId: partner.partnerId,
    } as PageViewEvent);

    const script = document.createElement('script');
    script.async = true;
    script.src = PARTNER_GTM_URL;
    document.head.appendChild(script);

    return () => {
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [partner.partnerId, location.pathname]);
};
