import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useToast } from '~/components/ui/Others/PtnToastProvider';
import { PtnTransition } from '~/components/ui/Others/PtnTransition';
import { PtnIcon } from '~/components/ui/PtnIcon';
import type { Toast } from '~/components/ui/Others/PtnToastProvider';

const SuccessIcon: React.FC = () => (
  <PtnIcon
    iconName="check-circle"
    type="solid"
    className="h-[20px] w-[20px] text-green-40"
  />
);

const ErrorIcon: React.FC = () => (
  <PtnIcon
    iconName="x-circle"
    type="solid"
    className="h-[20px] w-[20px] text-red-40"
  />
);

const PtnToastMessage: React.FC<{
  toast: Toast;
  removeToast: (id: number) => void;
}> = ({ toast, removeToast }) => {
  // transition を効かせるために、toasts のデータステータスと別にフラグを保持する
  const [isShown, setIsShown] = useState<boolean>(false);

  const handleFadeOpen = useCallback(() => setIsShown(true), []);
  const handleFadeClose = useCallback(() => {
    setIsShown(false);
    setTimeout(() => {
      removeToast(toast.id);
    }, 1000);
  }, [removeToast, toast.id]);

  useEffect(() => {
    handleFadeOpen();

    if (toast.status === 'success' && toast.duration > 0) {
      const timer = setTimeout(() => {
        handleFadeClose();
      }, toast.duration);

      return () => clearTimeout(timer);
    }
  }, [toast, handleFadeOpen, handleFadeClose]);

  return (
    <PtnTransition isShown={isShown}>
      <div
        className={clsx(
          'transition-opacity data-[enter]:opacity-100 data-[closed]:opacity-0',
          'flex items-center text-13 mb-2 px-2 py-1 bg-gray-5 text-gray-100 rounded-8',
        )}
      >
        <p className="mr-2">
          {toast.status === 'success' ? <SuccessIcon /> : <ErrorIcon />}
        </p>
        <p className="min-h-[30px] border-r-1 border-gray-10 flex items-center pr-2 mr-2 max-w-[300px]">
          {toast.message}
        </p>
        <button onClick={() => handleFadeClose()}>
          <PtnIcon iconName="x-mark" type="solid" className="w-3 h-3" />
        </button>
      </div>
    </PtnTransition>
  );
};

export const PtnToast: React.FC = () => {
  const { toasts, removeToast } = useToast();

  return (
    <div className="fixed bottom-2 right-5 z-50 flex flex-col items-end">
      {toasts.map((toast: Toast) => (
        <PtnToastMessage
          key={toast.id}
          toast={toast}
          removeToast={removeToast}
        />
      ))}
    </div>
  );
};
