import type React from 'react';
import { Transition } from '@headlessui/react';

interface TransitionProps {
  isShown: boolean;
  children: React.ReactNode;
}

export const PtnTransition: React.FC<TransitionProps> = ({
  children,
  isShown,
}) => {
  return <Transition show={isShown}>{children}</Transition>;
};
